// // import React, { useState, useEffect } from 'react';
// // import { QRCodeCanvas } from 'qrcode.react';
// // import './Payment.css';

// // const Payment = () => {
// //   const [qrCodeUrl, setQrCodeUrl] = useState(null);
// //   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8 minutes countdown
// //   const amountToBePaid = 500; // You can dynamically pass this value

// //   const transactionId = "SAMPLE_TRANSACTION_ID"; // Replace with actual data

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   const qrCodeValue = `upi://pay?pa=sample@upi&pn=SampleName&am=${amountToBePaid}&cu=INR`;

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         <div className="header">
// //           <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //         </div>

// //         <div className="logo-section">
// //           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //         </div>

// //         <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //         {/* Displaying the amount to be paid */}
// //         <p className="amount-to-be-paid">Amount to be paid: ₹{amountToBePaid}</p>

// //         <div className="qr-and-steps">
// //           <div className="qr-section">
// //             <QRCodeCanvas value={qrCodeValue} size={300} className="qr-code" />
// //             <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //           </div>

// //           {/* Steps to complete the payment */}
// //           <div className="payment-steps">
// //             <p className="step-text fade-in step1">Open your UPI app</p>
// //             <div className="down-arrow fade-in step1">&#x2193;</div>
// //             <p className="step-text fade-in step2">Scan the QR code</p>
// //             <div className="down-arrow fade-in step2">&#x2193;</div>
// //             <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //           </div>
// //         </div>

// //         <div className="payment-options">
// //           <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //           <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //           <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //           <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// // import React, { useState, useEffect } from 'react';
// // import { useLocation } from 'react-router-dom';

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState('');
// //   const [amount, setAmount] = useState(0);
// //   const [transactionId, setTransactionId] = useState('');
// //   const location = useLocation();

// //   // Extract the transaction ID from the URL query parameters
// //   const query = new URLSearchParams(location.search);
// //   const transactionIdFromUrl = query.get('transaction_id');

// //   useEffect(() => {
// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);

// //       // Fetch the QR code from the backend using the transaction ID
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl);  // Debug: Transaction ID in frontend

// //       fetch(`http://localhost:8067/get-qr-code/${transactionIdFromUrl}`)  // Replace with your actual API endpoint
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code);  // Debug: QR code received
// //           setQrCode(data.qr_code);      // Set the base64 QR code
// //           setAmount(data.amount);        // Set the amount to be displayed
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error);  // Debug: Log any error in fetching QR code
// //         });
// //     }
// //   }, [transactionIdFromUrl]);

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         <h2>Scan and Pay using UPI</h2>
// //         <p>Amount: ₹{amount}</p>

// //         {/* Display QR code if available */}
// //         {qrCode ? (
// //           <img 
// //             src={`data:image/png;base64,${qrCode}`}  // Display the base64 QR code
// //             alt="UPI QR Code"
// //             className="qr-code"
// //           />
// //         ) : (
// //           <p>Loading QR Code...</p>
// //         )}

// //         <div>
// //           <p>Transaction ID: {transactionId}</p>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// // import React, { useState, useEffect } from 'react';
// // import './Payment.css';
// // import { useLocation } from 'react-router-dom'; // Ensure this is imported to use location-based parameters

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Will hold the base64 QR code
// //   const [amount, setAmount] = useState(0); // Will hold the payment amount
// //   const [transactionId, setTransactionId] = useState('');
// //   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8 minutes countdown

// //   const location = useLocation(); // To get transaction ID from URL parameters

// //   useEffect(() => {
// //     // Extract transaction ID from URL query parameters
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');

// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

// //       // Fetch the QR code from the backend using the transaction ID
// //       fetch(`http://localhost:8067/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code); // Debugging log
// //           setQrCode(data.qr_code); // Set the base64 QR code
// //           setAmount(data.amount); // Set the amount to be displayed
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
// //         });
// //     }
// //   }, [location.search]);

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         <div className="header">
// //           <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //         </div>

// //         <div className="logo-section">
// //           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //         </div>

// //         <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //         {/* Displaying the amount to be paid */}
// //         <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //         <div className="qr-and-steps">
// //           <div className="qr-section">
// //             {/* Render the QR code fetched from the backend */}
// //             {qrCode ? (
// //               <img 
// //                 src={`data:image/png;base64,${qrCode}`}  // Use the base64 QR code from backend
// //                 alt="UPI QR Code"
// //                 className="qr-code"
// //               />
// //             ) : (
// //               <p>Loading QR Code...</p>  // Loading placeholder while QR is fetched
// //             )}
// //             <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //           </div>

// //           {/* Steps to complete the payment */}
// //           <div className="payment-steps">
// //             <p className="step-text fade-in step1">Open your UPI app</p>
// //             <div className="down-arrow fade-in step1">&#x2193;</div>
// //             <p className="step-text fade-in step2">Scan the QR code</p>
// //             <div className="down-arrow fade-in step2">&#x2193;</div>
// //             <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //           </div>
// //         </div>

// //         <div className="payment-options">
// //           <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //           <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //           <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //           <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// // import React, { useState, useEffect } from 'react';
// // import './Payment.css';
// // import { useLocation } from 'react-router-dom';

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Base64 QR code
// //   const [amount, setAmount] = useState(0); // Payment amount
// //   const [transactionId, setTransactionId] = useState('');
// //   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8 minutes countdown
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Status of the payment
// //   const location = useLocation();

// //   // Fetch the transaction ID from the URL parameters
// //   useEffect(() => {
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');
// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);

// //       // Fetch the QR code for the transaction ID
// //       fetch(`http://localhost:8067/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           setQrCode(data.qr_code);
// //           setAmount(data.amount);
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error);
// //         });

// //       // Poll the backend for payment status every 5 seconds
// //       const statusCheckInterval = setInterval(() => {
// //         fetch(`http://localhost:8067/api/check-payment-status/${transactionIdFromUrl}`)
// //           .then(response => response.json())
// //           .then(data => {
// //             console.log('Payment Status:', data.status);
// //             if (data.status.toLowerCase() === 'success') {
// //               clearInterval(statusCheckInterval); // Stop polling if success
// //               setPaymentStatus('SUCCESS');
// //             } else if (data.status.toLowerCase() === 'failed') {
// //               clearInterval(statusCheckInterval); // Stop polling if failed
// //               setPaymentStatus('FAILED');
// //             }
// //           })
// //           .catch(error => {
// //             console.error('Error fetching payment status:', error);
// //           });
// //       }, 5000);

// //       return () => clearInterval(statusCheckInterval); // Clear the interval when component unmounts
// //     }
// //   }, [location.search]);

// //   // Timer countdown for QR expiry
// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         <div className="header">
// //           <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //         </div>

// //         <div className="logo-section">
// //           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //         </div>

// //         <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //         {/* Display the amount to be paid */}
// //         <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //         {paymentStatus === 'SUCCESS' ? (
// //           // Display success animation when the payment is successful
// //           <div className="success-animation">
// //             <div className="tick-mark"></div>
// //             <p className="success-text">Payment Successful!</p>
// //           </div>
// //         ) : (
// //           <div className="qr-and-steps">
// //             <div className="qr-section">
// //               {/* Display the QR code or show loading text */}
// //               {qrCode ? (
// //                 <img 
// //                   src={`data:image/png;base64,${qrCode}`} 
// //                   alt="UPI QR Code"
// //                   className="qr-code"
// //                 />
// //               ) : (
// //                 <p>Loading QR Code...</p>
// //               )}
// //               <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //             </div>

// //             {/* Steps to complete the payment */}
// //             <div className="payment-steps">
// //               <p className="step-text fade-in step1">Open your UPI app</p>
// //               <div className="down-arrow fade-in step1">&#x2193;</div>
// //               <p className="step-text fade-in step2">Scan the QR code</p>
// //               <div className="down-arrow fade-in step2">&#x2193;</div>
// //               <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //             </div>
// //           </div>
// //         )}

// //         <div className="payment-options">
// //           <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //           <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //           <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //           <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;


// // import React, { useState, useEffect } from 'react';
// // import './Payment.css'; // Assuming you have your previous styles in this CSS file
// // import { useLocation } from 'react-router-dom'; // To handle URL parameters

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
// //   const [amount, setAmount] = useState(0); // Amount to be displayed
// //   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
// //   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8-minute countdown
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
// //   const [success, setSuccess] = useState(false); // For success animation
// //   const [failed, setFailed] = useState(false); // For failed payment status

// //   const location = useLocation(); // To get transaction ID from URL parameters

// //   useEffect(() => {
// //     // Extract transaction ID from URL query parameters
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');

// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

// //       // Fetch the QR code from the backend using the transaction ID
// //       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
// //       // fetch(`http://localhost:8019/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code); // Debugging log
// //           setQrCode(data.qr_code); // Set the base64 QR code
// //           setAmount(data.amount); // Set the amount to be displayed
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
// //         });
// //     }
// //   }, [location.search]);

// //   useEffect(() => {
// //     // Poll the backend every 3 seconds to check the payment status
// //     const interval = setInterval(() => {
// //       if (transactionId) {
// //         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //         // fetch(`http://localhost:8019/api/check-payment-status/${transactionId}`)
// //           .then(response => response.json())
// //           .then(data => {
// //             console.log("Payment status:", data.status);
// //             setPaymentStatus(data.status); // Set the payment status
            
// //             if (data.status === 'SUCCESS') {
// //               // Trigger success animation
// //               setQrCode(''); // Clear the QR code
// //               setSuccess(true); // Show success animation
// //               clearInterval(interval); // Stop polling
// //             } else if (data.status === 'FAILED') {
// //               // Handle failed payment
// //               setFailed(true);
// //               clearInterval(interval); // Stop polling
// //             }
// //           })
// //           .catch(error => {
// //             console.error('Error fetching payment status:', error);
// //           });
// //       }
// //     }, 3000); // Poll every 3 seconds

// //     return () => clearInterval(interval); // Clean up the interval on component unmount
// //   }, [transactionId]);

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         {/* Success animation */}
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <div className="header">
// //               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //             </div>

// //             <div className="logo-section">
// //               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //             </div>

// //             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //             {/* Displaying the amount to be paid */}
// //             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {/* Render the QR code fetched from the backend */}
// //                 {qrCode ? (
// //                   <img 
// //                     src={`data:image/png;base64,${qrCode}`}  // Use the base64 QR code from backend
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p>  // Loading placeholder while QR is fetched
// //                 )}
// //                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //               </div>

// //               {/* Steps to complete the payment */}
// //               <div className="payment-steps">
// //                 <p className="step-text fade-in step1">Open your UPI app</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan the QR code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //               </div>
// //             </div>

// //             <div className="payment-options">
// //               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //             </div>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;


// // import React, { useState, useEffect } from 'react';
// // import './Payment.css'; // Assuming you have your previous styles in this CSS file
// // import { useLocation } from 'react-router-dom'; // To handle URL parameters

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
// //   const [amount, setAmount] = useState(0); // Amount to be displayed
// //   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
// //   const [expiryTime, setExpiryTime] = useState(8 * 60); // 8-minute countdown
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
// //   const [success, setSuccess] = useState(false); // For success animation
// //   const [failed, setFailed] = useState(false); // For failed payment status

// //   const location = useLocation(); // To get transaction ID from URL parameters

// //   useEffect(() => {
// //     // Extract transaction ID from URL query parameters
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');

// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

// //       // Fetch the QR code from the backend using the transaction ID
// //       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code); // Debugging log
// //           setQrCode(data.qr_code); // Set the base64 QR code
// //           setAmount(data.amount); // Set the amount to be displayed
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
// //         });
// //     }
// //   }, [location.search]);

// //   useEffect(() => {
// //     // Poll the backend every 3 seconds to check the payment status
// //     const interval = setInterval(() => {
// //       if (transactionId) {
// //         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //           .then(response => response.json())
// //           .then(data => {
// //             console.log("Payment status:", data.status);
// //             setPaymentStatus(data.status); // Set the payment status

// //             if (data.status === 'SUCCESS') {
// //               // Trigger success animation
// //               setQrCode(''); // Clear the QR code
// //               setSuccess(true); // Show success animation
// //               clearInterval(interval); // Stop polling

// //               // Redirect to the callback URL after success
// //               if (data.payin_callback_url) {
// //                 window.location.href = data.payin_callback_url;
// //               }
// //             } else if (data.status === 'FAILED') {
// //               // Handle failed payment
// //               setFailed(true);
// //               clearInterval(interval); // Stop polling

// //               // Redirect to the callback URL after failure
// //               if (data.payin_callback_url) {
// //                 window.location.href = data.payin_callback_url;
// //               }
// //             }
// //           })
// //           .catch(error => {
// //             console.error('Error fetching payment status:', error);
// //           });
// //       }
// //     }, 3000); // Poll every 3 seconds

// //     return () => clearInterval(interval); // Clean up the interval on component unmount
// //   }, [transactionId]);

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         {/* Success animation */}
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <div className="header">
// //               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //             </div>

// //             <div className="logo-section">
// //               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //             </div>

// //             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //             {/* Displaying the amount to be paid */}
// //             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {/* Render the QR code fetched from the backend */}
// //                 {qrCode ? (
// //                   <img 
// //                     src={`data:image/png;base64,${qrCode}`}  // Use the base64 QR code from backend
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p>  // Loading placeholder while QR is fetched
// //                 )}
// //                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //               </div>

// //               {/* Steps to complete the payment */}
// //               <div className="payment-steps">
// //                 <p className="step-text fade-in step1">Open your UPI app</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan the QR code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //               </div>
// //             </div>

// //             <div className="payment-options">
// //               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //             </div>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// // import React, { useState, useEffect, useRef } from 'react';
// // import './Payment.css'; // Assuming you have your previous styles in this CSS file
// // import { useLocation } from 'react-router-dom'; // To handle URL parameters

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
// //   const [amount, setAmount] = useState(0); // Amount to be displayed
// //   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
// //   const [expiryTime, setExpiryTime] = useState(1.65 * 60); // 1-minute countdown
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
// //   const [success, setSuccess] = useState(false); // For success animation
// //   const [failed, setFailed] = useState(false); // For failed payment status
// //   const [callbackUrl, setCallbackUrl] = useState(''); // Callback URL

// //   const location = useLocation(); // To get transaction ID from URL parameters
// //   const pollingIntervalRef = useRef(null); // To store polling interval ID

// //   useEffect(() => {
// //     // Extract transaction ID from URL query parameters
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');

// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

// //       // Fetch the QR code from the backend using the transaction ID
// //       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code); // Debugging log
// //           setQrCode(data.qr_code); // Set the base64 QR code
// //           setAmount(data.amount); // Set the amount to be displayed
// //           setCallbackUrl(data.payin_callback_url); // Store the callback URL
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
// //         });
// //     }
// //   }, [location.search]);

// //   useEffect(() => {
// //     if (transactionId) {
// //       pollingIntervalRef.current = setInterval(() => {
// //         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //           .then(response => response.json())
// //           .then(data => {
// //             console.log("Payment status:", data.status);
// //             setPaymentStatus(data.status); // Set the payment status

// //             if (data.status === 'SUCCESS') {
// //               // Trigger success animation
// //               setQrCode(''); // Clear the QR code
// //               setSuccess(true); // Show success animation
// //               clearInterval(pollingIntervalRef.current); // Stop polling

// //               // Redirect to the callback URL after success
// //               if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             } else if (data.status === 'FAILED') {
// //               // Handle failed payment
// //               setFailed(true);
// //               clearInterval(pollingIntervalRef.current); // Stop polling

// //               // Redirect to the callback URL after failure
// //               if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }
// //           })
// //           .catch(error => {
// //             console.error('Error fetching payment status:', error);
// //           });
// //       }, 3000); // Poll every 3 seconds
// //     }

// //     return () => {
// //       clearInterval(pollingIntervalRef.current);
// //     };
// //   }, [transactionId, callbackUrl]);

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   useEffect(() => {
// //     if (expiryTime === 0 && paymentStatus === 'PENDING') {
// //       // Handle failed payment due to expiry
// //       setFailed(true);
// //       setPaymentStatus('FAILED');
// //       clearInterval(pollingIntervalRef.current);

// //       // Redirect to the callback URL after failure
// //       if (callbackUrl) {
// //         window.location.href = callbackUrl;
// //       }
// //     }
// //   }, [expiryTime, paymentStatus, callbackUrl]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         {/* Success animation */}
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <div className="header">
// //               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //             </div>

// //             <div className="logo-section">
// //               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //             </div>

// //             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //             {/* Displaying the amount to be paid */}
// //             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {/* Render the QR code fetched from the backend */}
// //                 {qrCode ? (
// //                   <img
// //                     src={`data:image/png;base64,${qrCode}`} // Use the base64 QR code from backend
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p> // Loading placeholder while QR is fetched
// //                 )}
// //                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //               </div>

// //               {/* Steps to complete the payment */}
// //               <div className="payment-steps">
// //                 <p className="step-text fade-in step1">Open your UPI app</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan the QR code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //               </div>
// //             </div>

// //             <div className="payment-options">
// //               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //             </div>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// // import React, { useState, useEffect, useRef } from 'react';
// // import './Payment.css'; // Assuming you have your previous styles in this CSS file
// // import { useLocation } from 'react-router-dom'; // To handle URL parameters

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
// //   const [amount, setAmount] = useState(0); // Amount to be displayed
// //   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
// //   const [expiryTime, setExpiryTime] = useState(1.65 * 60); // 1.65 minutes countdown
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
// //   const [success, setSuccess] = useState(false); // For success animation
// //   const [failed, setFailed] = useState(false); // For failed payment status
// //   const [callbackUrl, setCallbackUrl] = useState(''); // Callback URL
// //   const [successRedirectUrl, setSuccessRedirectUrl] = useState(''); // Added
// //   const [failureRedirectUrl, setFailureRedirectUrl] = useState(''); // Added

// //   const location = useLocation(); // To get transaction ID from URL parameters
// //   const pollingIntervalRef = useRef(null); // To store polling interval ID

// //   useEffect(() => {
// //     // Extract transaction ID from URL query parameters
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');

// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

// //       // Fetch the QR code from the backend using the transaction ID
// //       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code); // Debugging log
// //           setQrCode(data.qr_code); // Set the base64 QR code
// //           setAmount(data.amount); // Set the amount to be displayed
// //           setCallbackUrl(data.payin_callback_url); // Store the callback URL
// //           setSuccessRedirectUrl(data.success_redirect_url); // Store success redirect URL
// //           setFailureRedirectUrl(data.failure_redirect_url); // Store failure redirect URL
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
// //         });
// //     }
// //   }, [location.search]);

// //   useEffect(() => {
// //     if (transactionId) {
// //       pollingIntervalRef.current = setInterval(() => {
// //         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //           .then(response => response.json())
// //           .then(data => {
// //             console.log("Payment status:", data.status);
// //             setPaymentStatus(data.status); // Set the payment status

// //             if (data.status === 'SUCCESS') {
// //               // Trigger success animation
// //               setQrCode(''); // Clear the QR code
// //               setSuccess(true); // Show success animation
// //               clearInterval(pollingIntervalRef.current); // Stop polling

// //               // Redirect to the success redirect URL after success
// //               setTimeout(() => {
// //                 if (successRedirectUrl) {
// //                   window.location.href = successRedirectUrl;
// //                 } else if (callbackUrl) {
// //                   window.location.href = callbackUrl;
// //                 }
// //               }, 2000);
// //             } else if (data.status === 'FAILED') {
// //               // Handle failed payment
// //               setFailed(true);
// //               clearInterval(pollingIntervalRef.current); // Stop polling

// //               // Redirect to the failure redirect URL after failure
// //               setTimeout(() => {
// //                 if (failureRedirectUrl) {
// //                   window.location.href = failureRedirectUrl;
// //                 } else if (callbackUrl) {
// //                   window.location.href = callbackUrl;
// //                 }
// //               }, 2000);
// //             }
// //           })
// //           .catch(error => {
// //             console.error('Error fetching payment status:', error);
// //           });
// //       }, 3000); // Poll every 3 seconds
// //     }

// //     return () => {
// //       clearInterval(pollingIntervalRef.current);
// //     };
// //   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   useEffect(() => {
// //     if (expiryTime === 0 && paymentStatus === 'PENDING') {
// //       // Handle failed payment due to expiry
// //       setFailed(true);
// //       setPaymentStatus('FAILED');
// //       clearInterval(pollingIntervalRef.current);

// //       // Redirect to the failure redirect URL after timeout
// //       setTimeout(() => {
// //         if (failureRedirectUrl) {
// //           window.location.href = failureRedirectUrl;
// //         } else if (callbackUrl) {
// //           window.location.href = callbackUrl;
// //         }
// //       }, 2000);
// //     }
// //   }, [expiryTime, paymentStatus, callbackUrl, failureRedirectUrl]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = Math.floor(timeInSeconds % 60);
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         {/* Success animation */}
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <div className="header">
// //               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //             </div>

// //             <div className="logo-section">
// //               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //             </div>

// //             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //             {/* Displaying the amount to be paid */}
// //             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {/* Render the QR code fetched from the backend */}
// //                 {qrCode ? (
// //                   <img
// //                     src={`data:image/png;base64,${qrCode}`} // Use the base64 QR code from backend
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p> // Loading placeholder while QR is fetched
// //                 )}
// //                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //               </div>

// //               {/* Steps to complete the payment */}
// //               <div className="payment-steps">
// //                 <p className="step-text fade-in step1">Open your UPI app</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan the QR code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //               </div>
// //             </div>

// //             <div className="payment-options">
// //               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //             </div>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// // import React, { useState, useEffect, useRef } from 'react';
// // import './Payment.css'; // Assuming you have your previous styles in this CSS file
// // import { useLocation } from 'react-router-dom'; // To handle URL parameters

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); // Base64 QR code string
// //   const [amount, setAmount] = useState(0); // Amount to be displayed
// //   const [transactionId, setTransactionId] = useState(''); // Transaction ID from URL
// //   const [expiryTime, setExpiryTime] = useState(5 * 60); // 1.65 minutes countdown
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); // Payment status
// //   const [success, setSuccess] = useState(false); // For success animation
// //   const [failed, setFailed] = useState(false); // For failed payment status
// //   const [callbackUrl, setCallbackUrl] = useState(''); // Callback URL
// //   const [successRedirectUrl, setSuccessRedirectUrl] = useState(''); // Added
// //   const [failureRedirectUrl, setFailureRedirectUrl] = useState(''); // Added

// //   const location = useLocation(); // To get transaction ID from URL parameters
// //   const pollingIntervalRef = useRef(null); // To store polling interval ID

// //   useEffect(() => {
// //     // Extract transaction ID from URL query parameters
// //     const query = new URLSearchParams(location.search);
// //     const transactionIdFromUrl = query.get('transaction_id');

// //     if (transactionIdFromUrl) {
// //       setTransactionId(transactionIdFromUrl);
// //       console.log("Fetching QR code for transaction ID:", transactionIdFromUrl); // Debugging log

// //       // Fetch the QR code from the backend using the transaction ID
// //       fetch(`https://payin.payinfy.com/get-qr-code/${transactionIdFromUrl}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           console.log("QR Code received from backend:", data.qr_code); // Debugging log
// //           setQrCode(data.qr_code); // Set the base64 QR code
// //           setAmount(data.amount); // Set the amount to be displayed
// //           setCallbackUrl(data.payin_callback_url); // Store the callback URL
// //           setSuccessRedirectUrl(data.success_redirect_url); // Store success redirect URL
// //           setFailureRedirectUrl(data.failure_redirect_url); // Store failure redirect URL
// //         })
// //         .catch(error => {
// //           console.error('Error fetching QR code:', error); // Log any error in fetching QR code
// //         });
// //     }
// //   }, [location.search]);

// //   useEffect(() => {
// //     if (transactionId) {
// //       pollingIntervalRef.current = setInterval(() => {
// //         fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //           .then(response => response.json())
// //           .then(data => {
// //             console.log("Payment status:", data.status);
// //             setPaymentStatus(data.status); // Set the payment status

// //             if (data.status === 'SUCCESS') {
// //               // Trigger success animation
// //               setQrCode(''); // Clear the QR code
// //               setSuccess(true); // Show success animation
// //               clearInterval(pollingIntervalRef.current); // Stop polling

// //               // Redirect to the success redirect URL after success
// //               setTimeout(() => {
// //                 if (successRedirectUrl) {
// //                   // Create a form and submit via POST
// //                   const form = document.createElement('form');
// //                   form.method = 'POST';
// //                   form.action = successRedirectUrl;

// //                   // Add transaction ID as hidden input
// //                   const input = document.createElement('input');
// //                   input.type = 'hidden';
// //                   input.name = 'transaction_id';
// //                   input.value = transactionId;
// //                   form.appendChild(input);

// //                   // Append the form to body and submit it
// //                   document.body.appendChild(form);
// //                   form.submit();
// //                 } else if (callbackUrl) {
// //                   window.location.href = callbackUrl;
// //                 }
// //               }, 2000);
// //             } else if (data.status === 'FAILED') {
// //               // Handle failed payment
// //               setFailed(true);
// //               clearInterval(pollingIntervalRef.current); // Stop polling

// //               // Redirect to the failure redirect URL after failure
// //               setTimeout(() => {
// //                 if (failureRedirectUrl) {
// //                   // Create a form and submit via POST
// //                   const form = document.createElement('form');
// //                   form.method = 'POST';
// //                   form.action = failureRedirectUrl;

// //                   // Add transaction ID as hidden input
// //                   const input = document.createElement('input');
// //                   input.type = 'hidden';
// //                   input.name = 'transaction_id';
// //                   input.value = transactionId;
// //                   form.appendChild(input);

// //                   // Append the form to body and submit it
// //                   document.body.appendChild(form);
// //                   form.submit();
// //                 } else if (callbackUrl) {
// //                   window.location.href = callbackUrl;
// //                 }
// //               }, 2000);
// //             }
// //           })
// //           .catch(error => {
// //             console.error('Error fetching payment status:', error);
// //           });
// //       }, 3000); // Poll every 3 seconds
// //     }

// //     return () => {
// //       clearInterval(pollingIntervalRef.current);
// //     };
// //   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

// //   useEffect(() => {
// //     if (expiryTime > 0) {
// //       const timer = setInterval(() => {
// //         setExpiryTime((prev) => prev - 1);
// //       }, 1000);
// //       return () => clearInterval(timer);
// //     }
// //   }, [expiryTime]);

// //   useEffect(() => {
// //     if (expiryTime === 0 && paymentStatus === 'PENDING') {
// //       // Handle failed payment due to expiry
// //       setFailed(true);
// //       setPaymentStatus('FAILED');
// //       clearInterval(pollingIntervalRef.current);

// //       // Redirect to the failure redirect URL after timeout
// //       setTimeout(() => {
// //         if (failureRedirectUrl) {
// //           // Create a form and submit via POST
// //           const form = document.createElement('form');
// //           form.method = 'POST';
// //           form.action = failureRedirectUrl;

// //           // Add transaction ID as hidden input
// //           const input = document.createElement('input');
// //           input.type = 'hidden';
// //           input.name = 'transaction_id';
// //           input.value = transactionId;
// //           form.appendChild(input);

// //           // Append the form to body and submit it
// //           document.body.appendChild(form);
// //           form.submit();
// //         } else if (callbackUrl) {
// //           window.location.href = callbackUrl;
// //         }
// //       }, 2000);
// //     }
// //   }, [expiryTime, paymentStatus, callbackUrl, failureRedirectUrl, transactionId]);

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = Math.floor(timeInSeconds % 60);
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-widget-container">
// //       <div className="payment-widget">
// //         {/* Success animation */}
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             {/* <div className="header">
// //               <img src="/your-upi-logo.png" alt="UPI Logo" className="upi-logo" />
// //             </div> */}

// //             {/* <div className="logo-section">
// //               <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
// //             </div> */}

// //             <h2 className="payment-title">Scan and Pay using your UPI App</h2>

// //             {/* Displaying the amount to be paid */}
// //             <p className="amount-to-be-paid">Amount to be paid: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {/* Render the QR code fetched from the backend */}
// //                 {qrCode ? (
// //                   <img
// //                     src={`data:image/png;base64,${qrCode}`} // Use the base64 QR code from backend
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p> // Loading placeholder while QR is fetched
// //                 )}
// //                 <p className="expiry-time">Code will expire in {formatTime(expiryTime)}</p>
// //               </div>

// //               {/* Steps to complete the payment */}
// //               <div className="payment-steps">
// //                 <p className="step-text fade-in step1">Open your UPI app</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan the QR code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Enter your PIN and Pay</p>
// //               </div>
// //             </div>

// //             <div className="payment-options">
// //               <img src="/gpay-logo.png" alt="GPay" className="payment-logo" />
// //               <img src="/phonepe-logo.png" alt="PhonePe" className="payment-logo phonepe-logo" />
// //               <img src="/paytm-logo.png" alt="Paytm" className="payment-logo" />
// //               <img src="/bhim-logo.png" alt="BHIM" className="payment-logo" />
// //             </div>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;




// // PaymentBizzPaa.jsx (or Payment.jsx if you're replacing the old one entirely)
// // Make sure to adapt the fetch URLs to your actual environment.

// // import React, { useState, useEffect, useRef } from 'react';
// // import './Payment.css';
// // import { useLocation } from 'react-router-dom';

// // const PaymentBizzPaa = () => {
// //   const [qrCode, setQrCode] = useState('');
// //   const [amount, setAmount] = useState(0);
// //   const [transactionId, setTransactionId] = useState('');
// //   const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING');
// //   const [success, setSuccess] = useState(false);
// //   const [failed, setFailed] = useState(false);

// //   const [callbackUrl, setCallbackUrl] = useState('');
// //   const [successRedirectUrl, setSuccessRedirectUrl] = useState('');
// //   const [failureRedirectUrl, setFailureRedirectUrl] = useState('');
// //   const [paytmLink, setPaytmLink] = useState(''); // We'll store the paytm deep link if available

// //   const location = useLocation();
// //   const pollingIntervalRef = useRef(null);

// //   // 1) On mount, parse transaction_id from URL, fetch QR code
// //   useEffect(() => {
// //     const query = new URLSearchParams(location.search);
// //     const tid = query.get('transaction_id');

// //     if (tid) {
// //       setTransactionId(tid);
// //       // We call your GET QR code endpoint, which should return something like:
// //       // {
// //       //   "qr_code": <base64string>,
// //       //   "amount": 10,
// //       //   "payin_callback_url": "...",
// //       //   "success_redirect_url": "...",
// //       //   "failure_redirect_url": "...",
// //       //   "paytm_link": "paytmmp://..."
// //       // }
// //       // NOTE: You need to adjust your backend "get_qr_code" to also return the "paytm" link if you want.
// //       fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
// //         .then(res => res.json())
// //         .then(data => {
// //           setQrCode(data.qr_code || '');
// //           setAmount(data.amount || 0);
// //           setCallbackUrl(data.payin_callback_url || '');
// //           setSuccessRedirectUrl(data.success_redirect_url || '');
// //           setFailureRedirectUrl(data.failure_redirect_url || '');
// //           // If you store the paytm link from BizzPaa "applink": { "paytm": "..." } in your DB or pass it
// //           // to the frontend, set it here:
// //           // setPaytmLink(data.paytm_link || '');
// //         })
// //         .catch(err => {
// //           console.error('Error fetching QR code:', err);
// //         });
// //     }
// //   }, [location.search]);

// //   // 2) Poll for Payment Status every 3 seconds
// //   useEffect(() => {
// //     if (!transactionId) return;

// //     pollingIntervalRef.current = setInterval(() => {
// //       fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           setPaymentStatus(data.status);
// //           console.log('Payment status poll:', data.status);

// //           if (data.status === 'SUCCESS') {
// //             setSuccess(true);
// //             setQrCode('');
// //             clearInterval(pollingIntervalRef.current);

// //             // Redirect or post to success URL
// //             setTimeout(() => {
// //               if (successRedirectUrl) {
// //                 const form = document.createElement('form');
// //                 form.method = 'POST';
// //                 form.action = successRedirectUrl;
// //                 const input = document.createElement('input');
// //                 input.type = 'hidden';
// //                 input.name = 'transaction_id';
// //                 input.value = transactionId;
// //                 form.appendChild(input);
// //                 document.body.appendChild(form);
// //                 form.submit();
// //               } else if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }, 2000);
// //           } else if (data.status === 'FAILED') {
// //             setFailed(true);
// //             clearInterval(pollingIntervalRef.current);

// //             setTimeout(() => {
// //               if (failureRedirectUrl) {
// //                 const form = document.createElement('form');
// //                 form.method = 'POST';
// //                 form.action = failureRedirectUrl;
// //                 const input = document.createElement('input');
// //                 input.type = 'hidden';
// //                 input.name = 'transaction_id';
// //                 input.value = transactionId;
// //                 form.appendChild(input);
// //                 document.body.appendChild(form);
// //                 form.submit();
// //               } else if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }, 2000);
// //           }
// //         })
// //         .catch(error => {
// //           console.error('Error fetching payment status:', error);
// //         });
// //     }, 3000);

// //     return () => {
// //       clearInterval(pollingIntervalRef.current);
// //     };
// //   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

// //   // 3) Countdown timer
// //   useEffect(() => {
// //     if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
// //     const timer = setInterval(() => {
// //       setExpiryTime(prev => prev - 1);
// //     }, 1000);
// //     return () => clearInterval(timer);
// //   }, [expiryTime, paymentStatus]);

// //   // 4) If time hits 0, fail the transaction automatically
// //   useEffect(() => {
// //     if (expiryTime === 0 && paymentStatus === 'PENDING') {
// //       failTransaction();
// //     }
// //   }, [expiryTime, paymentStatus]);

// //   // Cancel Payment manually
// //   const cancelPayment = () => {
// //     failTransaction();
// //   };

// //   const failTransaction = () => {
// //     // Make API call to cancel
// //     fetch('https://payin.payinfy.com/api/cancel-payment/', {
// //       method: 'POST',
// //       headers: { 'Content-Type': 'application/json' },
// //       body: JSON.stringify({ transaction_id: transactionId })
// //     })
// //       .then(res => res.json())
// //       .then(() => {
// //         // Mark local UI as failed
// //         setFailed(true);
// //         setPaymentStatus('FAILED');
// //         clearInterval(pollingIntervalRef.current);

// //         // Redirect to failure
// //         setTimeout(() => {
// //           if (failureRedirectUrl) {
// //             const form = document.createElement('form');
// //             form.method = 'POST';
// //             form.action = failureRedirectUrl;
// //             const input = document.createElement('input');
// //             input.type = 'hidden';
// //             input.name = 'transaction_id';
// //             input.value = transactionId;
// //             form.appendChild(input);
// //             document.body.appendChild(form);
// //             form.submit();
// //           } else if (callbackUrl) {
// //             window.location.href = callbackUrl;
// //           }
// //         }, 2000);
// //       })
// //       .catch(err => {
// //         console.error('Error canceling payment:', err);
// //       });
// //   };

// //   // Determine if device is mobile
// //   const isMobile = () => {
// //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
// //       navigator.userAgent
// //     );
// //   };

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = Math.floor(timeInSeconds % 60);
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   // If user closes window, optionally fail the transaction (but might be too aggressive):
// //   // useEffect(() => {
// //   //   const handleBeforeUnload = () => {
// //   //     if (paymentStatus === 'PENDING') {
// //   //       failTransaction();
// //   //     }
// //   //   };
// //   //   window.addEventListener('beforeunload', handleBeforeUnload);
// //   //   return () => window.removeEventListener('beforeunload', handleBeforeUnload);
// //   // }, [paymentStatus]);

// //   return (
// //     <div className="payment-bizzpaa-container">
// //       <div className="payment-bizzpaa-widget">
// //         {success ? (
// //           <div className="success-animation-bizzpaa">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle-bizzpaa">
// //               <div className="checkmark-bizzpaa"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation-bizzpaa">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle-bizzpaa">
// //               <div className="crossmark-bizzpaa"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <h2 className="payment-title-bizzpaa">Scan &amp; Pay</h2>
// //             <p className="amount-to-be-paid-bizzpaa">Amount: ₹{amount}</p>

// //             <div className="qr-and-steps-bizzpaa">
// //               <div className="qr-section-bizzpaa">
// //                 {qrCode ? (
// //                   <img
// //                     src={`data:image/png;base64,${qrCode}`}
// //                     alt="UPI QR Code"
// //                     className="qr-code-bizzpaa"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p>
// //                 )}
// //                 <p className="expiry-time-bizzpaa">
// //                   Expires in {formatTime(expiryTime)}
// //                 </p>
// //               </div>

// //               <div className="payment-steps-bizzpaa">
// //                 <p className="step-text-bizzpaa fade-in-bizzpaa step1-bizzpaa">Open UPI App</p>
// //                 <div className="down-arrow-bizzpaa fade-in-bizzpaa step1-bizzpaa">&#x2193;</div>
// //                 <p className="step-text-bizzpaa fade-in-bizzpaa step2-bizzpaa">Scan QR Code</p>
// //                 <div className="down-arrow-bizzpaa fade-in-bizzpaa step2-bizzpaa">&#x2193;</div>
// //                 <p className="step-text-bizzpaa fade-in-bizzpaa step3-bizzpaa">Pay &amp; Confirm</p>
// //               </div>
// //             </div>

// //             {/* Mobile-only Paytm button */}
// //             {isMobile() && paytmLink && (
// //               <div className="paytm-button-section-bizzpaa">
// //                 <a href={paytmLink} className="paytm-button-bizzpaa">
// //                   Pay with Paytm
// //                 </a>
// //               </div>
// //             )}

// //             <button onClick={cancelPayment} className="cancel-button-bizzpaa">
// //               Cancel Payment
// //             </button>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;


// // import React, { useState, useEffect, useRef } from 'react';
// // import './Payment.css'; 
// // import { useLocation } from 'react-router-dom';

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState(''); 
// //   const [amount, setAmount] = useState(0); 
// //   const [transactionId, setTransactionId] = useState(''); 
// //   const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING'); 
// //   const [success, setSuccess] = useState(false); 
// //   const [failed, setFailed] = useState(false); 
// //   const [callbackUrl, setCallbackUrl] = useState(''); 
// //   const [successRedirectUrl, setSuccessRedirectUrl] = useState(''); 
// //   const [failureRedirectUrl, setFailureRedirectUrl] = useState(''); 
// //   const [paytmLink, setPaytmLink] = useState(''); // If provided by new BizzPaa "applink"

// //   const location = useLocation(); 
// //   const pollingIntervalRef = useRef(null);

// //   useEffect(() => {
// //     // Extract transactionId from ?transaction_id=xxx
// //     const query = new URLSearchParams(location.search);
// //     const tid = query.get('transaction_id');
// //     if (tid) {
// //       setTransactionId(tid);
// //       console.log("Fetching QR code for transaction ID:", tid);

// //       // 1) Fetch QR code info from backend. 
// //       //    Example: GET /get-qr-code/<transaction_id> 
// //       //    The response should have { qr_code, amount, payin_callback_url, success_redirect_url, failure_redirect_url, (and paytm link if any) }
// //       fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
// //         .then((res) => res.json())
// //         .then((data) => {
// //           console.log("QR Code Data from backend:", data);
// //           setQrCode(data.qr_code || '');
// //           setAmount(data.amount || 0);
// //           setCallbackUrl(data.payin_callback_url || '');
// //           setSuccessRedirectUrl(data.success_redirect_url || '');
// //           setFailureRedirectUrl(data.failure_redirect_url || '');
// //           // If you are storing the paytm link in the backend, add it here:
// //           // e.g. setPaytmLink(data.paytm_link || '');
// //         })
// //         .catch((err) => {
// //           console.error("Error fetching QR code:", err);
// //         });
// //     }
// //   }, [location.search]);

// //   // 2) Poll the transaction status 
// //   useEffect(() => {
// //     if (!transactionId) return;
// //     pollingIntervalRef.current = setInterval(() => {
// //       // GET /api/check-payment-status/<transaction_id> 
// //       fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //         .then((res) => res.json())
// //         .then((data) => {
// //           console.log("Payment status poll:", data.status);
// //           setPaymentStatus(data.status);

// //           if (data.status === 'SUCCESS') {
// //             // Payment success
// //             setSuccess(true);
// //             setQrCode('');
// //             clearInterval(pollingIntervalRef.current);

// //             setTimeout(() => {
// //               if (successRedirectUrl) {
// //                 // POST to success redirect
// //                 const form = document.createElement('form');
// //                 form.method = 'POST';
// //                 form.action = successRedirectUrl;
// //                 const input = document.createElement('input');
// //                 input.type = 'hidden';
// //                 input.name = 'transaction_id';
// //                 input.value = transactionId;
// //                 form.appendChild(input);
// //                 document.body.appendChild(form);
// //                 form.submit();
// //               } else if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }, 2000);
// //           } else if (data.status === 'FAILED') {
// //             // Payment failed
// //             setFailed(true);
// //             clearInterval(pollingIntervalRef.current);

// //             setTimeout(() => {
// //               if (failureRedirectUrl) {
// //                 const form = document.createElement('form');
// //                 form.method = 'POST';
// //                 form.action = failureRedirectUrl;
// //                 const input = document.createElement('input');
// //                 input.type = 'hidden';
// //                 input.name = 'transaction_id';
// //                 input.value = transactionId;
// //                 form.appendChild(input);
// //                 document.body.appendChild(form);
// //                 form.submit();
// //               } else if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }, 2000);
// //           }
// //         })
// //         .catch((err) => {
// //           console.error("Error polling payment status:", err);
// //         });
// //     }, 3000);

// //     return () => {
// //       clearInterval(pollingIntervalRef.current);
// //     };
// //   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

// //   // 3) Countdown Timer
// //   useEffect(() => {
// //     if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
// //     const timer = setInterval(() => {
// //       setExpiryTime((prev) => prev - 1);
// //     }, 1000);
// //     return () => clearInterval(timer);
// //   }, [expiryTime, paymentStatus]);

// //   // 4) If time hits 0 and still PENDING, mark as failed
// //   useEffect(() => {
// //     if (expiryTime === 0 && paymentStatus === 'PENDING') {
// //       failTransaction();
// //     }
// //   }, [expiryTime, paymentStatus]);

// //   // “Cancel Payment” Button
// //   const cancelPayment = () => {
// //     failTransaction();
// //   };

// //   const failTransaction = () => {
// //     // POST /api/cancel-payment/ with { transaction_id }
// //     fetch('https://payin.payinfy.com/api/cancel-payment/', {
// //       method: 'POST',
// //       headers: { 'Content-Type': 'application/json' },
// //       body: JSON.stringify({ transaction_id: transactionId }),
// //     })
// //       .then((res) => res.json())
// //       .then(() => {
// //         setFailed(true);
// //         setPaymentStatus('FAILED');
// //         clearInterval(pollingIntervalRef.current);

// //         setTimeout(() => {
// //           if (failureRedirectUrl) {
// //             const form = document.createElement('form');
// //             form.method = 'POST';
// //             form.action = failureRedirectUrl;
// //             const input = document.createElement('input');
// //             input.type = 'hidden';
// //             input.name = 'transaction_id';
// //             input.value = transactionId;
// //             form.appendChild(input);
// //             document.body.appendChild(form);
// //             form.submit();
// //           } else if (callbackUrl) {
// //             window.location.href = callbackUrl;
// //           }
// //         }, 2000);
// //       })
// //       .catch((err) => {
// //         console.error("Error canceling payment:", err);
// //       });
// //   };

// //   // Determine if device is mobile
// //   const isMobile = () => {
// //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
// //       navigator.userAgent
// //     );
// //   };

// //   // Helper to format time mm:ss
// //   const formatTime = (timeInSeconds) => {
// //     const m = Math.floor(timeInSeconds / 60);
// //     const s = timeInSeconds % 60;
// //     return `${m}:${s < 10 ? '0' : ''}${s}`;
// //   };

// //   return (
// //     <div className="payment-container">
// //       <div className="payment-widget">
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <h2 className="payment-title">Scan &amp; Pay</h2>
// //             <p className="amount-label">Amount: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {qrCode ? (
// //                   <img
// //                     src={`data:image/png;base64,${qrCode}`}
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p>
// //                 )}
// //                 <p className="expiry-time">Expires in {formatTime(expiryTime)}</p>
// //               </div>

// //               <div className="steps-section">
// //                 <p className="step-text fade-in step1">Open any UPI App</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan QR Code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Authorize &amp; Pay</p>
// //               </div>
// //             </div>

// //             {/* If there's a Paytm link, show it only on mobile */}
// //             {isMobile() && paytmLink && (
// //               <div className="paytm-section">
// //                 <a href={paytmLink} className="paytm-btn">
// //                   Pay with Paytm
// //                 </a>
// //               </div>
// //             )}

// //             <button onClick={cancelPayment} className="cancel-btn">
// //               Cancel Payment
// //             </button>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;




// // import React, { useState, useEffect, useRef } from 'react';
// // import './Payment.css';
// // import { useLocation } from 'react-router-dom';

// // const Payment = () => {
// //   const [qrCode, setQrCode] = useState('');
// //   const [amount, setAmount] = useState(0);
// //   const [transactionId, setTransactionId] = useState('');
// //   const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
// //   const [paymentStatus, setPaymentStatus] = useState('PENDING');
// //   const [success, setSuccess] = useState(false);
// //   const [failed, setFailed] = useState(false);

// //   const [callbackUrl, setCallbackUrl] = useState('');
// //   const [successRedirectUrl, setSuccessRedirectUrl] = useState('');
// //   const [failureRedirectUrl, setFailureRedirectUrl] = useState('');
// //   const [paytmLink, setPaytmLink] = useState(''); // We'll store the Paytm deep link if available

// //   const location = useLocation();
// //   const pollingIntervalRef = useRef(null);

// //   // 1) On mount, parse transaction_id from URL, fetch QR code
// //   useEffect(() => {
// //     const query = new URLSearchParams(location.search);
// //     const tid = query.get('transaction_id');

// //     if (tid) {
// //       setTransactionId(tid);
// //       // We call your GET QR code endpoint, which should return something like:
// //       // {
// //       //   "qr_code": <base64string>,
// //       //   "amount": 10,
// //       //   "payin_callback_url": "...",
// //       //   "success_redirect_url": "...",
// //       //   "failure_redirect_url": "...",
// //       //   "paytm_link": "paytmmp://..."
// //       // }
// //       fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
// //         .then(res => res.json())
// //         .then(data => {
// //           setQrCode(data.qr_code || '');
// //           setAmount(data.amount || 0);
// //           setCallbackUrl(data.payin_callback_url || '');
// //           setSuccessRedirectUrl(data.success_redirect_url || '');
// //           setFailureRedirectUrl(data.failure_redirect_url || '');
// //           // If you store the Paytm link from your backend:
// //           // setPaytmLink(data.paytm_link || '');
// //         })
// //         .catch(err => {
// //           console.error('Error fetching QR code:', err);
// //         });
// //     }
// //   }, [location.search]);

// //   // 2) Poll for Payment Status every 3 seconds
// //   useEffect(() => {
// //     if (!transactionId) return;

// //     pollingIntervalRef.current = setInterval(() => {
// //       fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
// //         .then(response => response.json())
// //         .then(data => {
// //           setPaymentStatus(data.status);
// //           console.log('Payment status poll:', data.status);

// //           if (data.status === 'SUCCESS') {
// //             setSuccess(true);
// //             setQrCode('');
// //             clearInterval(pollingIntervalRef.current);

// //             // Redirect or post to success URL
// //             setTimeout(() => {
// //               if (successRedirectUrl) {
// //                 const form = document.createElement('form');
// //                 form.method = 'POST';
// //                 form.action = successRedirectUrl;
// //                 const input = document.createElement('input');
// //                 input.type = 'hidden';
// //                 input.name = 'transaction_id';
// //                 input.value = transactionId;
// //                 form.appendChild(input);
// //                 document.body.appendChild(form);
// //                 form.submit();
// //               } else if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }, 2000);
// //           } else if (data.status === 'FAILED') {
// //             setFailed(true);
// //             clearInterval(pollingIntervalRef.current);

// //             setTimeout(() => {
// //               if (failureRedirectUrl) {
// //                 const form = document.createElement('form');
// //                 form.method = 'POST';
// //                 form.action = failureRedirectUrl;
// //                 const input = document.createElement('input');
// //                 input.type = 'hidden';
// //                 input.name = 'transaction_id';
// //                 input.value = transactionId;
// //                 form.appendChild(input);
// //                 document.body.appendChild(form);
// //                 form.submit();
// //               } else if (callbackUrl) {
// //                 window.location.href = callbackUrl;
// //               }
// //             }, 2000);
// //           }
// //         })
// //         .catch(error => {
// //           console.error('Error fetching payment status:', error);
// //         });
// //     }, 3000);

// //     return () => {
// //       clearInterval(pollingIntervalRef.current);
// //     };
// //   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

// //   // 3) Countdown timer
// //   useEffect(() => {
// //     if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
// //     const timer = setInterval(() => {
// //       setExpiryTime(prev => prev - 1);
// //     }, 1000);
// //     return () => clearInterval(timer);
// //   }, [expiryTime, paymentStatus]);

// //   // 4) If time hits 0, fail the transaction automatically
// //   useEffect(() => {
// //     if (expiryTime === 0 && paymentStatus === 'PENDING') {
// //       failTransaction();
// //     }
// //   }, [expiryTime, paymentStatus]);

// //   // Cancel Payment manually
// //   const cancelPayment = () => {
// //     failTransaction();
// //   };

// //   const failTransaction = () => {
// //     // Make API call to cancel
// //     fetch('https://payin.payinfy.com/api/cancel-payment/', {
// //       method: 'POST',
// //       headers: { 'Content-Type': 'application/json' },
// //       body: JSON.stringify({ transaction_id: transactionId })
// //     })
// //       .then(res => res.json())
// //       .then(() => {
// //         // Mark local UI as failed
// //         setFailed(true);
// //         setPaymentStatus('FAILED');
// //         clearInterval(pollingIntervalRef.current);

// //         // Redirect to failure
// //         setTimeout(() => {
// //           if (failureRedirectUrl) {
// //             const form = document.createElement('form');
// //             form.method = 'POST';
// //             form.action = failureRedirectUrl;
// //             const input = document.createElement('input');
// //             input.type = 'hidden';
// //             input.name = 'transaction_id';
// //             input.value = transactionId;
// //             form.appendChild(input);
// //             document.body.appendChild(form);
// //             form.submit();
// //           } else if (callbackUrl) {
// //             window.location.href = callbackUrl;
// //           }
// //         }, 2000);
// //       })
// //       .catch(err => {
// //         console.error('Error canceling payment:', err);
// //       });
// //   };

// //   // Determine if device is mobile
// //   const isMobile = () => {
// //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
// //       navigator.userAgent
// //     );
// //   };

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = Math.floor(timeInSeconds % 60);
// //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
// //   };

// //   return (
// //     <div className="payment-container">
// //       <div className="payment-widget">
// //         {success ? (
// //           <div className="success-animation">
// //             <h2>Payment Successful!</h2>
// //             <div className="checkmark-circle">
// //               <div className="checkmark"></div>
// //             </div>
// //           </div>
// //         ) : failed ? (
// //           <div className="failed-animation">
// //             <h2>Payment Failed</h2>
// //             <div className="failed-circle">
// //               <div className="crossmark"></div>
// //             </div>
// //           </div>
// //         ) : (
// //           <>
// //             <h2 className="payment-title">Scan &amp; Pay</h2>
// //             <p className="amount-to-be-paid">Amount: ₹{amount}</p>

// //             <div className="qr-and-steps">
// //               <div className="qr-section">
// //                 {qrCode ? (
// //                   <img
// //                     src={`data:image/png;base64,${qrCode}`}
// //                     alt="UPI QR Code"
// //                     className="qr-code"
// //                   />
// //                 ) : (
// //                   <p>Loading QR Code...</p>
// //                 )}
// //                 <p className="expiry-time">
// //                   Expires in {formatTime(expiryTime)}
// //                 </p>
// //               </div>

// //               <div className="steps-section">
// //                 <p className="step-text fade-in step1">Open UPI App</p>
// //                 <div className="down-arrow fade-in step1">&#x2193;</div>
// //                 <p className="step-text fade-in step2">Scan QR Code</p>
// //                 <div className="down-arrow fade-in step2">&#x2193;</div>
// //                 <p className="step-text fade-in step3">Pay &amp; Confirm</p>
// //               </div>
// //             </div>

// //             {/* Mobile-only Paytm button */}
// //             {isMobile() && paytmLink && (
// //               <div className="paytm-button-section">
// //                 <a href={paytmLink} className="paytm-button">
// //                   Pay with Paytm
// //                 </a>
// //               </div>
// //             )}

// //             <button onClick={cancelPayment} className="cancel-button">
// //               Cancel Payment
// //             </button>
// //           </>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Payment;



// import React, { useState, useEffect, useRef } from 'react';
// import './Payment.css';
// import { useLocation } from 'react-router-dom';

// const Payment = () => {
//   const [qrCode, setQrCode] = useState('');
//   const [amount, setAmount] = useState(0);
//   const [transactionId, setTransactionId] = useState('');
//   const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
//   const [paymentStatus, setPaymentStatus] = useState('PENDING');
//   const [success, setSuccess] = useState(false);
//   const [failed, setFailed] = useState(false);

//   const [callbackUrl, setCallbackUrl] = useState('');
//   const [successRedirectUrl, setSuccessRedirectUrl] = useState('');
//   const [failureRedirectUrl, setFailureRedirectUrl] = useState('');
//   const [paytmLink, setPaytmLink] = useState('');

//   const location = useLocation();
//   const pollingIntervalRef = useRef(null);

//   // 1) On mount, read transaction_id from URL, then fetch the QR code & data
//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const tid = query.get('transaction_id');

//     if (tid) {
//       setTransactionId(tid);
//       fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
//         .then((res) => res.json())
//         .then((data) => {
//           setQrCode(data.qr_code || '');
//           setAmount(data.amount || 0);
//           setCallbackUrl(data.payin_callback_url || '');
//           setSuccessRedirectUrl(data.success_redirect_url || '');
//           setFailureRedirectUrl(data.failure_redirect_url || '');
//           // If your backend returns a paytm_link, set it:
//           // setPaytmLink(data.paytm_link || '');
//         })
//         .catch((err) => {
//           console.error('Error fetching QR code:', err);
//         });
//     }
//   }, [location.search]);

//   // 2) Poll the payment status every 3 seconds
//   useEffect(() => {
//     if (!transactionId) return;

//     pollingIntervalRef.current = setInterval(() => {
//       fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//         .then((res) => res.json())
//         .then((data) => {
//           setPaymentStatus(data.status);
//           console.log('Payment status:', data.status);

//           if (data.status === 'SUCCESS') {
//             setSuccess(true);
//             setQrCode('');
//             clearInterval(pollingIntervalRef.current);
//             // Post/Redirect after small delay
//             setTimeout(() => {
//               if (successRedirectUrl) {
//                 const form = document.createElement('form');
//                 form.method = 'POST';
//                 form.action = successRedirectUrl;
//                 const input = document.createElement('input');
//                 input.type = 'hidden';
//                 input.name = 'transaction_id';
//                 input.value = transactionId;
//                 form.appendChild(input);
//                 document.body.appendChild(form);
//                 form.submit();
//               } else if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }, 2000);
//           } else if (data.status === 'FAILED') {
//             setFailed(true);
//             clearInterval(pollingIntervalRef.current);
//             // Post/Redirect after small delay
//             setTimeout(() => {
//               if (failureRedirectUrl) {
//                 const form = document.createElement('form');
//                 form.method = 'POST';
//                 form.action = failureRedirectUrl;
//                 const input = document.createElement('input');
//                 input.type = 'hidden';
//                 input.name = 'transaction_id';
//                 input.value = transactionId;
//                 form.appendChild(input);
//                 document.body.appendChild(form);
//                 form.submit();
//               } else if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }, 2000);
//           }
//         })
//         .catch((err) => {
//           console.error('Error polling payment status:', err);
//         });
//     }, 3000);

//     return () => {
//       clearInterval(pollingIntervalRef.current);
//     };
//   }, [transactionId, successRedirectUrl, failureRedirectUrl, callbackUrl]);

//   // 3) Countdown timer
//   useEffect(() => {
//     if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
//     const timer = setInterval(() => {
//       setExpiryTime((prev) => prev - 1);
//     }, 1000);
//     return () => clearInterval(timer);
//   }, [expiryTime, paymentStatus]);

//   // 4) If time hits 0, mark as fail
//   useEffect(() => {
//     if (expiryTime === 0 && paymentStatus === 'PENDING') {
//       failTransaction();
//     }
//   }, [expiryTime, paymentStatus]);

//   // Cancel Payment
//   const cancelPayment = () => {
//     failTransaction();
//   };

//   const failTransaction = () => {
//     fetch('https://payin.payinfy.com/api/cancel-payment/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ transaction_id: transactionId }),
//     })
//       .then((res) => res.json())
//       .then(() => {
//         setFailed(true);
//         setPaymentStatus('FAILED');
//         clearInterval(pollingIntervalRef.current);
//         setTimeout(() => {
//           if (failureRedirectUrl) {
//             const form = document.createElement('form');
//             form.method = 'POST';
//             form.action = failureRedirectUrl;
//             const input = document.createElement('input');
//             input.type = 'hidden';
//             input.name = 'transaction_id';
//             input.value = transactionId;
//             form.appendChild(input);
//             document.body.appendChild(form);
//             form.submit();
//           } else if (callbackUrl) {
//             window.location.href = callbackUrl;
//           }
//         }, 2000);
//       })
//       .catch((err) => {
//         console.error('Error canceling payment:', err);
//       });
//   };

//   // Detect mobile
//   const isMobile = () => {
//     return (
//       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//         navigator.userAgent
//       )
//     );
//   };

//   // Format mm:ss
//   const formatTime = (timeInSec) => {
//     const m = Math.floor(timeInSec / 60);
//     const s = timeInSec % 60;
//     return `${m}:${s < 10 ? '0' : ''}${s}`;
//   };

//   // Render
//   return (
//     <div className="payinfy-container">
//       <div className="payinfy-widget">
//         {success ? (
//           <div className="success-animation">
//             <h2>Payment Successful!</h2>
//             <div className="circle success-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-animation">
//             <h2>Payment Failed</h2>
//             <div className="circle failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             {/* Header / Title */}
//             <h2 className="widget-title">Secure UPI Payment</h2>
//             <p className="payinfy-amount">
//               Amount: <span>₹{amount}</span>
//             </p>

//             {/* QR + Instructions */}
//             <div className="payinfy-body">
//               <div className="qr-section">
//                 {qrCode ? (
//                   <img
//                     src={`data:image/png;base64,${qrCode}`}
//                     alt="UPI QR"
//                     className="qr-code"
//                   />
//                 ) : (
//                   <p>Generating QR Code...</p>
//                 )}
//                 <p className="timer-text">Expires in {formatTime(expiryTime)}</p>
//               </div>

//               <div className="instruction-section">
//                 <p className="inst-text fade-in step1">
//                   1. Open any UPI App (GPay, PhonePe, etc.)
//                 </p>
//                 <div className="down-arrow fade-in step1">↓</div>
//                 <p className="inst-text fade-in step2">2. Scan this QR</p>
//                 <div className="down-arrow fade-in step2">↓</div>
//                 <p className="inst-text fade-in step3">3. Authorize &amp; Pay</p>
//               </div>
//             </div>

//             {/* Payment brand logos */}
//             <div className="brand-logos">
//               <img src="/gpay-logo.png" alt="GPay" className="brand-logo" />
//               <img
//                 src="/phonepe-logo.png"
//                 alt="PhonePe"
//                 className="brand-logo phonepe"
//               />
//               <img src="/paytm-logo.png" alt="Paytm" className="brand-logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="brand-logo" />
//             </div>

//             {/* If there's a paytm link, show button on mobile */}
//             {isMobile() && paytmLink && (
//               <div className="mobile-paytm-btn">
//                 <a href={paytmLink} className="paytm-app-link">
//                   Pay with Paytm
//                 </a>
//               </div>
//             )}

//             {/* Cancel Payment */}
//             <button className="cancel-btn" onClick={cancelPayment}>
//               Cancel Payment
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect, useRef } from 'react';
// import './Payment.css';  // Named "payment.css" as requested
// import { useLocation } from 'react-router-dom';

// const Payment = () => {
//   const [qrCode, setQrCode] = useState('');
//   const [amount, setAmount] = useState(0);
//   const [transactionId, setTransactionId] = useState('');
//   const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
//   const [paymentStatus, setPaymentStatus] = useState('PENDING');
//   const [success, setSuccess] = useState(false);
//   const [failed, setFailed] = useState(false);

//   const [callbackUrl, setCallbackUrl] = useState('');
//   const [successRedirectUrl, setSuccessRedirectUrl] = useState('');
//   const [failureRedirectUrl, setFailureRedirectUrl] = useState('');
//   const [paytmLink, setPaytmLink] = useState('');

//   const location = useLocation();
//   const pollingIntervalRef = useRef(null);

//   // 1) Parse transaction_id from URL, fetch the QR code data
//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const tid = query.get('transaction_id');
//     if (!tid) return;

//     setTransactionId(tid);

//     fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
//       .then((res) => res.json())
//       .then((data) => {
//         setQrCode(data.qr_code || '');
//         setAmount(data.amount || 0);
//         setCallbackUrl(data.payin_callback_url || '');
//         setSuccessRedirectUrl(data.success_redirect_url || '');
//         setFailureRedirectUrl(data.failure_redirect_url || '');
//         setPaytmLink(data.paytm_link || '');  // <-- store the paytm deep link
//       })
//       .catch((err) => {
//         console.error('Error fetching QR code:', err);
//       });
//   }, [location.search]);

//   // 2) Poll for payment status every 3s
//   useEffect(() => {
//     if (!transactionId) return;

//     pollingIntervalRef.current = setInterval(() => {
//       fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//         .then((res) => res.json())
//         .then((data) => {
//           setPaymentStatus(data.status);
//           console.log('Polled Payment Status:', data.status);

//           if (data.status === 'SUCCESS') {
//             setSuccess(true);
//             setQrCode('');
//             clearInterval(pollingIntervalRef.current);

//             setTimeout(() => {
//               if (successRedirectUrl) {
//                 // POST to success redirect
//                 const form = document.createElement('form');
//                 form.method = 'POST';
//                 form.action = successRedirectUrl;
//                 const input = document.createElement('input');
//                 input.type = 'hidden';
//                 input.name = 'transaction_id';
//                 input.value = transactionId;
//                 form.appendChild(input);
//                 document.body.appendChild(form);
//                 form.submit();
//               } else if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }, 1500);
//           } else if (data.status === 'FAILED') {
//             setFailed(true);
//             clearInterval(pollingIntervalRef.current);

//             setTimeout(() => {
//               if (failureRedirectUrl) {
//                 const form = document.createElement('form');
//                 form.method = 'POST';
//                 form.action = failureRedirectUrl;
//                 const input = document.createElement('input');
//                 input.type = 'hidden';
//                 input.name = 'transaction_id';
//                 input.value = transactionId;
//                 form.appendChild(input);
//                 document.body.appendChild(form);
//                 form.submit();
//               } else if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }, 1500);
//           }
//         })
//         .catch((err) => {
//           console.error('Error polling status:', err);
//         });
//     }, 3000);

//     return () => clearInterval(pollingIntervalRef.current);
//   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

//   // 3) Countdown Timer
//   useEffect(() => {
//     if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
//     const timer = setInterval(() => {
//       setExpiryTime((prev) => prev - 1);
//     }, 1000);
//     return () => clearInterval(timer);
//   }, [expiryTime, paymentStatus]);

//   // 4) If time hits 0, fail the transaction automatically
//   useEffect(() => {
//     if (expiryTime === 0 && paymentStatus === 'PENDING') {
//       failTransaction();
//     }
//   }, [expiryTime, paymentStatus]);

//   const failTransaction = () => {
//     fetch('https://payin.payinfy.com/api/cancel-payment/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ transaction_id: transactionId }),
//     })
//       .then((res) => res.json())
//       .then(() => {
//         setFailed(true);
//         setPaymentStatus('FAILED');
//         clearInterval(pollingIntervalRef.current);

//         setTimeout(() => {
//           if (failureRedirectUrl) {
//             const form = document.createElement('form');
//             form.method = 'POST';
//             form.action = failureRedirectUrl;
//             const input = document.createElement('input');
//             input.type = 'hidden';
//             input.name = 'transaction_id';
//             input.value = transactionId;
//             form.appendChild(input);
//             document.body.appendChild(form);
//             form.submit();
//           } else if (callbackUrl) {
//             window.location.href = callbackUrl;
//           }
//         }, 1500);
//       })
//       .catch((err) => {
//         console.error('Error canceling payment:', err);
//       });
//   };

//   const cancelPayment = () => {
//     failTransaction();
//   };

//   const isMobile = () => {
//     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//       navigator.userAgent
//     );
//   };

//   const formatTime = (sec) => {
//     const m = Math.floor(sec / 60);
//     const s = sec % 60;
//     return `${m}:${s < 10 ? '0' : ''}${s}`;
//   };

//   // For "Download QR"
//   const downloadQr = () => {
//     // Convert base64 to a "blob" and download
//     if (!qrCode) return;
//     const link = document.createElement('a');
//     link.href = `data:image/png;base64,${qrCode}`;
//     link.download = 'upi-qr.png';
//     link.click();
//   };

//   return (
//     <div className="payment-container">
//       <div className="payment-wrapper">
//         {success ? (
//           <div className="success-view">
//             <h2>Payment Successful!</h2>
//             <div className="circle success-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-view">
//             <h2>Payment Failed</h2>
//             <div className="circle failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <h2 className="title">Pay via UPI</h2>
//             <p className="amount-label">Amount: ₹{amount}</p>

//             <div className="qr-section">
//               {qrCode ? (
//                 <img
//                   src={`data:image/png;base64,${qrCode}`}
//                   alt="UPI QR"
//                   className="qr-image"
//                 />
//               ) : (
//                 <p>Loading QR Code...</p>
//               )}
//               <p className="timer-text">Expires in {formatTime(expiryTime)}</p>

//               {/* If in mobile, show "Download QR" */}
//               {isMobile() && qrCode && (
//                 <button className="download-btn" onClick={downloadQr}>
//                   Download QR
//                 </button>
//               )}
//             </div>

//             {/* Payment logos. We also show the Paytm button if paytmLink is available. */}
//             <div className="payment-options">
//               <img src="/gpay-logo.png" alt="GPay" className="option-logo" />
//               <img src="/phonepe-logo.png" alt="PhonePe" className="option-logo" />
//               <img src="/paytm-logo.png" alt="Paytm" className="option-logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="option-logo" />
//             </div>

//             {isMobile() && paytmLink && (
//               <div className="paytm-section">
//                 <a href={paytmLink} className="paytm-link-btn">
//                   Open Paytm
//                 </a>
//               </div>
//             )}

//             <button className="cancel-btn" onClick={cancelPayment}>
//               Cancel Payment
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;



// import React, { useState, useEffect, useRef } from 'react';
// import './Payment.css';
// import { useLocation } from 'react-router-dom';

// const Payment = () => {
//   const [qrCode, setQrCode] = useState('');
//   const [amount, setAmount] = useState(0);
//   const [transactionId, setTransactionId] = useState('');
//   const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
//   const [paymentStatus, setPaymentStatus] = useState('PENDING');
//   const [success, setSuccess] = useState(false);
//   const [failed, setFailed] = useState(false);

//   const [callbackUrl, setCallbackUrl] = useState('');
//   const [successRedirectUrl, setSuccessRedirectUrl] = useState('');
//   const [failureRedirectUrl, setFailureRedirectUrl] = useState('');

//   // NEW: store deep links for GPay, PhonePe, Paytm
//   const [gpayLink, setGpayLink] = useState('');
//   const [phonepeLink, setPhonepeLink] = useState('');
//   const [paytmLink, setPaytmLink] = useState('');

//   const location = useLocation();
//   const pollingIntervalRef = useRef(null);

//   // 1) Parse transaction_id from URL, fetch the QR code data
//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const tid = query.get('transaction_id');
//     if (!tid) return;

//     setTransactionId(tid);

//     fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
//       .then((res) => res.json())
//       .then((data) => {
//         setQrCode(data.qr_code || '');
//         setAmount(data.amount || 0);
//         setCallbackUrl(data.payin_callback_url || '');
//         setSuccessRedirectUrl(data.success_redirect_url || '');
//         setFailureRedirectUrl(data.failure_redirect_url || '');

//         // NEW: store the links if provided by your backend
//         setPaytmLink(data.paytm_link || '');
//         setGpayLink(data.gpay_link || '');
//         setPhonepeLink(data.phonepe_link || '');
//       })
//       .catch((err) => {
//         console.error('Error fetching QR code:', err);
//       });
//   }, [location.search]);

//   // 2) Poll for payment status every 3s
//   useEffect(() => {
//     if (!transactionId) return;

//     pollingIntervalRef.current = setInterval(() => {
//       fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
//         .then((res) => res.json())
//         .then((data) => {
//           setPaymentStatus(data.status);
//           console.log('Polled Payment Status:', data.status);

//           if (data.status === 'SUCCESS') {
//             setSuccess(true);
//             setQrCode('');
//             clearInterval(pollingIntervalRef.current);

//             setTimeout(() => {
//               if (successRedirectUrl) {
//                 // POST to success redirect
//                 const form = document.createElement('form');
//                 form.method = 'POST';
//                 form.action = successRedirectUrl;
//                 const input = document.createElement('input');
//                 input.type = 'hidden';
//                 input.name = 'transaction_id';
//                 input.value = transactionId;
//                 form.appendChild(input);
//                 document.body.appendChild(form);
//                 form.submit();
//               } else if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }, 1500);
//           } else if (data.status === 'FAILED') {
//             setFailed(true);
//             clearInterval(pollingIntervalRef.current);

//             setTimeout(() => {
//               if (failureRedirectUrl) {
//                 const form = document.createElement('form');
//                 form.method = 'POST';
//                 form.action = failureRedirectUrl;
//                 const input = document.createElement('input');
//                 input.type = 'hidden';
//                 input.name = 'transaction_id';
//                 input.value = transactionId;
//                 form.appendChild(input);
//                 document.body.appendChild(form);
//                 form.submit();
//               } else if (callbackUrl) {
//                 window.location.href = callbackUrl;
//               }
//             }, 1500);
//           }
//         })
//         .catch((err) => {
//           console.error('Error polling status:', err);
//         });
//     }, 3000);

//     return () => clearInterval(pollingIntervalRef.current);
//   }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

//   // 3) Countdown Timer
//   useEffect(() => {
//     if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
//     const timer = setInterval(() => {
//       setExpiryTime((prev) => prev - 1);
//     }, 1000);
//     return () => clearInterval(timer);
//   }, [expiryTime, paymentStatus]);

//   // 4) If time hits 0, fail the transaction automatically
//   useEffect(() => {
//     if (expiryTime === 0 && paymentStatus === 'PENDING') {
//       failTransaction();
//     }
//   }, [expiryTime, paymentStatus]);

//   const failTransaction = () => {
//     fetch('https://payin.payinfy.com/api/cancel-payment/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ transaction_id: transactionId }),
//     })
//       .then((res) => res.json())
//       .then(() => {
//         setFailed(true);
//         setPaymentStatus('FAILED');
//         clearInterval(pollingIntervalRef.current);

//         setTimeout(() => {
//           if (failureRedirectUrl) {
//             const form = document.createElement('form');
//             form.method = 'POST';
//             form.action = failureRedirectUrl;
//             const input = document.createElement('input');
//             input.type = 'hidden';
//             input.name = 'transaction_id';
//             input.value = transactionId;
//             form.appendChild(input);
//             document.body.appendChild(form);
//             form.submit();
//           } else if (callbackUrl) {
//             window.location.href = callbackUrl;
//           }
//         }, 1500);
//       })
//       .catch((err) => {
//         console.error('Error canceling payment:', err);
//       });
//   };

//   const cancelPayment = () => {
//     failTransaction();
//   };

//   const isMobile = () => {
//     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//       navigator.userAgent
//     );
//   };

//   const formatTime = (sec) => {
//     const m = Math.floor(sec / 60);
//     const s = sec % 60;
//     return `${m}:${s < 10 ? '0' : ''}${s}`;
//   };

//   // For "Download QR"
//   const downloadQr = () => {
//     if (!qrCode) return;
//     const link = document.createElement('a');
//     link.href = `data:image/png;base64,${qrCode}`;
//     link.download = 'upi-qr.png';
//     link.click();
//   };

//   return (
//     <div className="payment-container">
//       <div className="payment-wrapper">
//         {success ? (
//           <div className="success-view">
//             <h2>Payment Successful!</h2>
//             <div className="circle success-circle">
//               <div className="checkmark"></div>
//             </div>
//           </div>
//         ) : failed ? (
//           <div className="failed-view">
//             <h2>Payment Failed</h2>
//             <div className="circle failed-circle">
//               <div className="crossmark"></div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <h2 className="title">Pay via UPI</h2>
//             <p className="amount-label">Amount: ₹{amount}</p>

//             <div className="qr-section">
//               {qrCode ? (
//                 <img
//                   src={`data:image/png;base64,${qrCode}`}
//                   alt="UPI QR"
//                   className="qr-image"
//                 />
//               ) : (
//                 <p>Loading QR Code...</p>
//               )}
//               <p className="timer-text">Expires in {formatTime(expiryTime)}</p>

//               {/* If mobile, show "Download QR" */}
//               {isMobile() && qrCode && (
//                 <button className="download-btn" onClick={downloadQr}>
//                   Download QR
//                 </button>
//               )}
//             </div>

//             {/* Payment brand logos - optional, purely for visual */}
//             <div className="payment-logos">
//               <img src="/gpay-logo.png" alt="GPay" className="logo" />
//               <img src="/phonepe-logo.png" alt="PhonePe" className="logo" />
//               <img src="/paytm-logo.png" alt="Paytm" className="logo" />
//               <img src="/bhim-logo.png" alt="BHIM" className="logo" />
//             </div>

//             {/* Show deep links as brand-colored buttons only on mobile */}
//             {isMobile() && (
//               <div className="app-links">
//                 {gpayLink && (
//                   <a href={gpayLink} className="app-link-btn gpay-btn">
//                     Open GPay
//                   </a>
//                 )}
//                 {phonepeLink && (
//                   <a href={phonepeLink} className="app-link-btn phonepe-btn">
//                     Open PhonePe
//                   </a>
//                 )}
//                 {paytmLink && (
//                   <a href={paytmLink} className="app-link-btn paytm-btn">
//                     Open Paytm
//                   </a>
//                 )}
//               </div>
//             )}

//             {/* Cancel payment as a hyperlink (replaces the button) */}
//             <div className="cancel-section">
//               <a className="cancel-link" onClick={cancelPayment}>
//                 Cancel Payment
//               </a>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Payment;



import React, { useState, useEffect, useRef } from 'react';
import './Payment.css';
import { useLocation } from 'react-router-dom';

const Payment = () => {
  const [qrCode, setQrCode] = useState('');
  const [amount, setAmount] = useState(0);
  const [transactionId, setTransactionId] = useState('');
  const [expiryTime, setExpiryTime] = useState(5 * 60); // 5 minutes
  const [paymentStatus, setPaymentStatus] = useState('PENDING');
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const [callbackUrl, setCallbackUrl] = useState('');
  const [successRedirectUrl, setSuccessRedirectUrl] = useState('');
  const [failureRedirectUrl, setFailureRedirectUrl] = useState('');

  // We'll store the original Paytm link plus derived GPay & PhonePe links
  const [paytmLink, setPaytmLink] = useState('');
  const [gpayLink, setGpayLink] = useState('');
  const [phonepeLink, setPhonepeLink] = useState('');

  const location = useLocation();
  const pollingIntervalRef = useRef(null);

  // -----------------------------
  // 1) Helper functions
  // -----------------------------
  const parseParamsFromPaytmLink = (link) => {
    // Typically Paytm link is something like:
    // paytmmp://cash_wallet?pa=merchant1605660.augp@aubank&am=10&pn=Beeinbox&tn=94853&tr=94853&cu=INR...
    // We'll parse the query params after the "?"
    const queryPart = link.split('?')[1];
    if (!queryPart) return {};

    const params = {};
    queryPart.split('&').forEach((pair) => {
      const [key, value] = pair.split('=');
      params[key] = decodeURIComponent(value || '');
    });
    return params;
  };

  const generateGpayLink = (params) => {
    // Example format:
    // gpay://upi/pay?pa=<pa>&pn=<pn>&am=<am>&tn=<tn>&tr=<tr>&cu=<cu>
    const { pa, am, pn, tn, tr, cu } = params;
    return `gpay://upi/pay?pa=${pa}&pn=${pn}&am=${am}&tn=${tn}&tr=${tr}&cu=${cu}`;
  };

  const generatePhonepeLink = (params) => {
    // Example format:
    // phonepe://upi/pay?pa=<pa>&pn=<pn>&am=<am>&tn=<tn>&tr=<tr>&cu=<cu>
    const { pa, am, pn, tn, tr, cu } = params;
    return `phonepe://pay?pa=${pa}&pn=${pn}&am=${am}&tn=${tn}&tr=${tr}&cu=${cu}`;
  };

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const formatTime = (sec) => {
    const m = Math.floor(sec / 60);
    const s = sec % 60;
    return `${m}:${s < 10 ? '0' : ''}${s}`;
  };

  // -----------------------------
  // 2) Lifecycle: Fetch initial data
  // -----------------------------
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tid = query.get('transaction_id');
    if (!tid) return;

    setTransactionId(tid);

    fetch(`https://payin.payinfy.com/get-qr-code/${tid}`)
      .then((res) => res.json())
      .then((data) => {
        setQrCode(data.qr_code || '');
        setAmount(data.amount || 0);
        setCallbackUrl(data.payin_callback_url || '');
        setSuccessRedirectUrl(data.success_redirect_url || '');
        setFailureRedirectUrl(data.failure_redirect_url || '');

        // Store the original Paytm link
        const paytm = data.paytm_link || '';
        setPaytmLink(paytm);

        // Now parse out parameters from the Paytm link and build GPay/PhonePe links
        if (paytm) {
          const params = parseParamsFromPaytmLink(paytm);

          // Build GPay link
          const gpay = generateGpayLink(params);
          setGpayLink(gpay);

          // Build PhonePe link
          const phonepe = generatePhonepeLink(params);
          setPhonepeLink(phonepe);
        }
      })
      .catch((err) => {
        console.error('Error fetching QR code:', err);
      });
  }, [location.search]);

  // -----------------------------
  // 3) Poll for payment status every 3s
  // -----------------------------
  useEffect(() => {
    if (!transactionId) return;

    pollingIntervalRef.current = setInterval(() => {
      fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}`)
        .then((res) => res.json())
        .then((data) => {
          setPaymentStatus(data.status);
          console.log('Polled Payment Status:', data.status);

          if (data.status === 'SUCCESS') {
            setSuccess(true);
            setQrCode('');
            clearInterval(pollingIntervalRef.current);

            setTimeout(() => {
              if (successRedirectUrl) {
                // POST to success redirect
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = successRedirectUrl;
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'transaction_id';
                input.value = transactionId;
                form.appendChild(input);
                document.body.appendChild(form);
                form.submit();
              } else if (callbackUrl) {
                window.location.href = callbackUrl;
              }
            }, 1500);
          } else if (data.status === 'FAILED') {
            setFailed(true);
            clearInterval(pollingIntervalRef.current);

            setTimeout(() => {
              if (failureRedirectUrl) {
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = failureRedirectUrl;
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'transaction_id';
                input.value = transactionId;
                form.appendChild(input);
                document.body.appendChild(form);
                form.submit();
              } else if (callbackUrl) {
                window.location.href = callbackUrl;
              }
            }, 1500);
          }
        })
        .catch((err) => {
          console.error('Error polling status:', err);
        });
    }, 3000);

    return () => clearInterval(pollingIntervalRef.current);
  }, [transactionId, callbackUrl, successRedirectUrl, failureRedirectUrl]);

  // -----------------------------
  // 4) Timer countdown
  // -----------------------------
  useEffect(() => {
    if (expiryTime <= 0 || paymentStatus !== 'PENDING') return;
    const timer = setInterval(() => {
      setExpiryTime((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [expiryTime, paymentStatus]);

  // Automatically fail if time hits 0
  useEffect(() => {
    if (expiryTime === 0 && paymentStatus === 'PENDING') {
      failTransaction();
    }
  }, [expiryTime, paymentStatus]);

  // -----------------------------
  // 5) Fail / Cancel Payment
  // -----------------------------
  const failTransaction = () => {
    fetch('https://payin.payinfy.com/api/cancel-payment/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transaction_id: transactionId }),
    })
      .then((res) => res.json())
      .then(() => {
        setFailed(true);
        setPaymentStatus('FAILED');
        clearInterval(pollingIntervalRef.current);

        setTimeout(() => {
          if (failureRedirectUrl) {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = failureRedirectUrl;
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'transaction_id';
            input.value = transactionId;
            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
          } else if (callbackUrl) {
            window.location.href = callbackUrl;
          }
        }, 1500);
      })
      .catch((err) => {
        console.error('Error canceling payment:', err);
      });
  };

  const cancelPayment = () => {
    failTransaction();
  };

  // -----------------------------
  // 6) For "Download QR"
  // -----------------------------
  const downloadQr = () => {
    if (!qrCode) return;
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${qrCode}`;
    link.download = 'upi-qr.png';
    link.click();
  };

  // -----------------------------
  // RENDER
  // -----------------------------
  return (
    <div className="payment-container">
      <div className="payment-wrapper">
        {success ? (
          <div className="success-view">
            <h2>Payment Successful!</h2>
            <div className="circle success-circle">
              <div className="checkmark"></div>
            </div>
          </div>
        ) : failed ? (
          <div className="failed-view">
            <h2>Payment Failed</h2>
            <div className="circle failed-circle">
              <div className="crossmark"></div>
            </div>
          </div>
        ) : (
          <>
            <h2 className="title">Pay via UPI</h2>
            <p className="amount-label">Amount: ₹{amount}</p>

            <div className="qr-section">
              {qrCode ? (
                <img
                  src={`data:image/png;base64,${qrCode}`}
                  alt="UPI QR"
                  className="qr-image"
                />
              ) : (
                <p>Loading QR Code...</p>
              )}
              <p className="timer-text">Expires in {formatTime(expiryTime)}</p>

              {/* If mobile, show "Download QR" */}
              {isMobile() && qrCode && (
                <button className="download-btn" onClick={downloadQr}>
                  Download QR
                </button>
              )}
            </div>

            {/* Payment brand logos (purely visual) */}
            <div className="payment-logos">
              <img src="/gpay-logo.png" alt="GPay" className="logo" />
              <img src="/phonepe-logo.png" alt="PhonePe" className="logo" />
              <img src="/paytm-logo.png" alt="Paytm" className="logo" />
              <img src="/bhim-logo.png" alt="BHIM" className="logo" />
            </div>

            {/* Show deep link buttons if on mobile */}
            {isMobile() && (
              <div className="app-links">
                {gpayLink && (
                  <a href={gpayLink} className="app-link-btn gpay-btn">
                    Open Google Pay
                  </a>
                )}
                {phonepeLink && (
                  <a href={phonepeLink} className="app-link-btn phonepe-btn">
                    Open PhonePe
                  </a>
                )}
                {paytmLink && (
                  <a href={paytmLink} className="app-link-btn paytm-btn">
                    Open Paytm
                  </a>
                )}
              </div>
            )}

            {/* Cancel payment as a hyperlink */}
            <div className="cancel-section">
              <a className="cancel-link" onClick={cancelPayment}>
                Cancel Payment
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Payment;
